import { Button } from "@/components/ui/button";
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Loading } from "@/components/ui/loading";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";
import { ImageSelector } from "@/components/image-selector/image-selector";
import { CreateRouteDialog } from "@/modules/routes/dialogs/create-route.dialog";
import { RouteQueries } from "@/modules/routes/queries/route.queries";
import { TourLegQueries } from "@/modules/routes/queries/tour-leg.queries";
import { TourQueries } from "@/modules/tours/queries/tour.queries";
import { Route } from "@/routes/tours/$tourId/route-builder";
import polyline from "@mapbox/polyline";
import type { Route as IRoute, TourLegWithRoute } from "@repo/types";
import { Link } from "@tanstack/react-router";
import * as turf from "@turf/turf";
import type * as GeoJSON from "geojson";
import { Check, ChevronsUpDown } from "lucide-react";
import * as React from "react";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import * as z from "zod";

export const TourRouteBuilder: FunctionComponent = () => {
	const { tourId } = Route.useParams();

	const { data: tour } = TourQueries.useTour(tourId);
	const { data: routes = [], isPending: areRoutesPending = [] } =
		RouteQueries.useRoutes();

	const { data: tourLegs = [], isPending: areTourLegsPending } =
		TourLegQueries.useTourLegsByTourId(tourId);

	if (tour == null || areRoutesPending || areTourLegsPending) {
		return <Loading />;
	}

	const { number_of_days } = tour;

	const days = Array.from({ length: number_of_days }, (_, i) => i + 1);

	const tourRoutes: TourLegWithRoute[] = tourLegs.map((tourLeg) => {
		const route = routes.find((route) => route.id === tourLeg.route_id);
		return {
			...tourLeg,
			route: route ?? null,
		};
	});

	const groupedByDay = tourRoutes.reduce(
		(acc, tourLeg) => {
			const day = tourLeg.day;
			return {
				// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
				...acc,
				[day]: tourLeg,
			};
		},
		{} as Record<number, TourLegWithRoute>,
	);

	return (
		<div className="grid gap-6">
			{days.map((day) => {
				const tourLeg = groupedByDay[day];
				return (
					<TourDayItem key={day} tourId={tourId} day={day} tourLeg={tourLeg} />
				);
			})}
		</div>
	);
};

type TourDayItemProps = {
	tourId: string;
	day: number;
	tourLeg: TourLegWithRoute | null;
};

const TourLegSchema = z.object({
	type: z.enum(["RIDING_DAY", "REST_DAY"]),
	routeId: z.string().nullable(),
	name: z.string(),
	description: z.string(),
	images: z.array(z.string()).optional(),
});

type TourLegSchemaValues = z.infer<typeof TourLegSchema>;

const TourDayItem: FunctionComponent<TourDayItemProps> = ({
	tourId,
	day,
	tourLeg,
}) => {
	const upsertTourLegMutation = TourLegQueries.useUpsertTourLeg();

	const { data: routes = [] } = RouteQueries.useRoutes();

	const form = useForm<TourLegSchemaValues>({
		defaultValues: {
			name: tourLeg?.name ?? "",
			description: tourLeg?.description ?? "",
			type: tourLeg?.type ?? "REST_DAY",
			routeId: tourLeg?.route_id ?? "",
			images: tourLeg?.images ?? [],
		},
	});

	const { routeId, type } = useWatch({
		control: form.control,
	});

	const onSubmit = async (values: TourLegSchemaValues) => {
		const updatedTourLeg = await upsertTourLegMutation.mutateAsync({
			id: tourLeg?.id,
			tour_id: tourId,
			day,
			route_id: values.type === "RIDING_DAY" ? values.routeId : null,
			type: values.type,
			name: values.name,
			description: values.description,
			images: values.images,
		});
		form.reset({
			...updatedTourLeg,
			routeId: updatedTourLeg.route_id,
			type: updatedTourLeg.type,
			images: updatedTourLeg.images ?? [],
		});
	};

	const selectedRoute = useMemo(
		() => routes.find((route) => route.id === routeId),
		[routeId, routes],
	);

	return (
		<Form {...form}>
			<Card>
				<CardHeader>
					<CardTitle>
						Day {day}
						{" - "}
						{type === "REST_DAY" && (
							<span className={"text-gray-400"}>Rest day</span>
						)}
						{type === "RIDING_DAY" && (
							tourLeg?.route != null ? (
									<>
										{selectedRoute?.from} to {selectedRoute?.to}
									</>
								) : (
									<span className={"text-gray-400"}>No Route set</span>
								)
						)}
					</CardTitle>
				</CardHeader>
				<CardContent className={"flex gap-4"}>
					<div className={"h-60 aspect-square flex relative"}>
						<div
							className={"flex-1 bg-white rounded-xl overflow-hidden relative"}
						>
							{selectedRoute != null && (
								<TourDayItemMap
									key={selectedRoute.id}
									day={day}
									route={selectedRoute}
								/>
							)}
						</div>
					</div>
					<form className={"flex flex-col flex-1 gap-2"}>
						<FormField
							control={form.control}
							name="type"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Type</FormLabel>
									<FormControl>
										<Select
											value={field.value}
											onValueChange={(value) => {
												field.onChange(value);

												if (value === "REST_DAY") {
													form.setValue("routeId", null);
												}
											}}
										>
											<SelectTrigger>
												<SelectValue defaultValue={"Select type"} />
											</SelectTrigger>
											<SelectContent>
												<SelectItem value="RIDING_DAY">Riding Day</SelectItem>
												<SelectItem value="REST_DAY">Rest Day</SelectItem>
											</SelectContent>
										</Select>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						{type === "RIDING_DAY" && (
							<FormField
								control={form.control}
								name="routeId"
								render={({ field }) => (
									<TourLegRouteSelect
										routes={routes}
										routeId={field.value}
										onChange={field.onChange}
									/>
								)}
							/>
						)}

						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Name</FormLabel>
									<FormControl>
										<Input
											{...field}
											type="text"
											placeholder="Rest day in Taupo"
										/>
									</FormControl>
									<FormDescription>
										Used to identify the leg in the tour. If not provided, the
										route name will be used instead. Only use this if the day
										deviates or is a rest day.
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="description"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Description</FormLabel>
									<FormControl>
										<Textarea {...field} className="min-h-32" />
									</FormControl>
									<FormDescription />
									<FormMessage />
								</FormItem>
							)}
						/>

						{type === "REST_DAY" && (
							<FormField
								control={form.control}
								name="images"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Images</FormLabel>
										<FormControl>
											<ImageSelector
												onChange={field.onChange}
												initialSelectedFiles={field.value || []}
											>
												<Button>Select images</Button>
											</ImageSelector>
										</FormControl>
										<FormDescription>
											Add images to showcase this rest day location. Images can be reordered by dragging.
										</FormDescription>
										<FormMessage />
									</FormItem>
								)}
							/>
						)}
					</form>
				</CardContent>
				<CardFooter className="border-t px-6 py-4 justify-end">
					<Button
						onClick={form.handleSubmit(onSubmit)}
						isLoading={upsertTourLegMutation.isPending}
						disabled={
							!form.formState.isDirty || upsertTourLegMutation.isPending
						}
					>
						Save leg
					</Button>
				</CardFooter>
			</Card>
		</Form>
	);
};

const TourDayItemMap: FunctionComponent<{ day: number; route: IRoute }> = (
	props,
) => {
	const { route } = props;

	const geometry = useMemo(
		() =>
			route.geometry != null
				? (JSON.parse(route.geometry) as GeoJSON.LineString)
				: null,
		[route.geometry],
	);

	if (geometry == null || route.geometry == null) {
		return null;
	}

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-expect-error
	const newGeometry = turf.simplify(turf.feature(geometry), {
		highQuality: true,
		tolerance: 0.008,
	});

	if (!("geometry" in newGeometry)) {
		return null;
	}

	const newGeometryEncoded = polyline.fromGeoJSON(newGeometry.geometry);

	const url = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/path-5+13a34a-1(${encodeURIComponent(
		newGeometryEncoded,
	)})/auto/500x500?padding=50&access_token=pk.eyJ1IjoidXJiYW5jaHJpc3kiLCJhIjoiY2xxMGZoazh1MTdybjJqcW5rZ3kxcWV5aCJ9.bMB2O5cRlBdc7xCjGVQVpA`;

	return <img src={url} alt="Route map" className="w-full h-full" />;
};

type TourLegRouteSelectProps = {
	routes: IRoute[];
	routeId: string | null;
	onChange: (routeId: string) => void;
};

const TourLegRouteSelect: FunctionComponent<TourLegRouteSelectProps> = ({
	routes,
	routeId,
	onChange,
}) => {
	const [isOpen, setIsOpen] = React.useState(false);

	const selectedRoute = useMemo(
		() => routes.find((route) => route.id === routeId),
		[routeId, routes],
	);

	return (
		<FormItem>
			<FormLabel>Route</FormLabel>
			<FormControl>
				<Popover open={isOpen} onOpenChange={setIsOpen}>
					<div className={"flex gap-2"}>
						<PopoverTrigger asChild>
							<Button
								variant="outline"
								role="combobox"
								// aria-expanded={open}
								// disabled={isPending}
								className="w-full justify-between"
							>
								{selectedRoute != null
									? selectedRoute.name !== ""
										? selectedRoute.name
										: `${selectedRoute.from} - ${selectedRoute?.to}`
									: "Select route..."}
								<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
							</Button>
						</PopoverTrigger>

						<Button
							variant={"secondary"}
							disabled={selectedRoute == null}
							asChild
						>
							<Link
								to={"/routes/$routeId"}
								params={{ routeId: selectedRoute?.id ?? "" }}
							>
								Edit route
							</Link>
						</Button>

						<CreateRouteDialog>
							<Button variant={"outline"}>Create route</Button>
						</CreateRouteDialog>
					</div>
					<PopoverContent className=" p-0" align={"start"} side={"left"}>
						<Command>
							<CommandInput placeholder="Search routes..." />
							<CommandList>
								<CommandEmpty>No routes found.</CommandEmpty>
								<CommandGroup>
									{routes?.map((route) => (
										<CommandItem
											key={route.id}
											value={route.id}
											onSelect={(routeId) => {
												setIsOpen(false);

												const route = routes.find((r) => r.id === routeId);
												if (route == null) {
													return;
												}

												onChange(routeId);
											}}
										>
											<Check
												className={cn(
													"mr-2 h-4 w-4",
													routeId === route.id ? "opacity-100" : "opacity-0",
												)}
											/>
											{route?.name !== ""
												? route.name
												: `${route?.from} - ${route?.to}`}
										</CommandItem>
									))}
								</CommandGroup>
							</CommandList>
						</Command>
					</PopoverContent>
				</Popover>
			</FormControl>
			<FormMessage />
		</FormItem>
	);
};
