export type Environment = "next-js" | "vite" | "unknown";

export type SupabaseConfig = {
	supabaseUrl: string;
	supabaseKey: string;
};

const DefaultProductionSupabaseConfig: SupabaseConfig = {
	supabaseUrl: "https://rjcogfxactzvhldzuxfb.supabase.co",
	supabaseKey:
		"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJqY29nZnhhY3R6dmhsZHp1eGZiIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDIyNjgwNzEsImV4cCI6MjAxNzg0NDA3MX0.2-nMK_XS-8PKSb0AixNS-8lToPB4d4mczFoeNWBln68",
};

export class ConfigService {
	getEnvironment(): Environment {
		if (
			process?.env?.NEXT_PUBLIC_SUPABASE_URL != null &&
			process?.env?.NEXT_PUBLIC_SUPABASE_ANON_KEY != null
		) {
			return "next-js";
		}

		if (
			// @ts-ignore
			import.meta?.env?.VITE_SUPABASE_URL != null &&
			// @ts-ignore
			import.meta?.env?.VITE_SUPABASE_ANON_KEY != null
		) {
			return "vite";
		}

		return "unknown";
	}

	getSupabaseConfig(): SupabaseConfig {
		const environment = this.getEnvironment();

		switch (environment) {
			case "next-js":
				return {
					supabaseUrl: process.env.NEXT_PUBLIC_SUPABASE_URL ?? "",
					supabaseKey: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY ?? "",
				};
			case "vite":
				return {
					// @ts-ignore
					supabaseUrl: import.meta.env.VITE_SUPABASE_URL,
					// @ts-ignore
					supabaseKey: import.meta.env.VITE_SUPABASE_ANON_KEY,
				};
			default:
				return DefaultProductionSupabaseConfig;
		}
	}
}
